<!-- 角色权限分配弹窗 -->
<template>
  <el-dialog
      title="分配权限"
      :visible="visible"
      width="50%"
      :destroy-on-close="true"
      :lock-scroll="false"
      @update:visible="updateVisible">
    <el-checkbox
        v-model="checkAll"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
    >
      全选
    </el-checkbox>
    <el-scrollbar
        v-loading="authLoading"
        style="height: 50vh;"
        wrapStyle="overflow-x: hidden;">
      <el-tree
          :check-strictly="isCheck"
          :expand-on-click-node="false"
          ref="tree"
          :data="authData"
          :props="{label: 'title'}"
          node-key="id"
          :default-expand-all="false"
          :default-checked-keys="[checked]"
          show-checkbox>
        <span slot-scope="{ data }">
          <!--<i :class="data.icon"></i>-->
          <span> {{ data.label }}</span>
        </span>
      </el-tree>
    </el-scrollbar>
    <div slot="footer">
      <el-button @click="updateVisible(false)">取消</el-button>
      <el-button
          type="primary"
          @click="save"
          :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

//引入的接口
import {cascader_list,get_relevancy,relevancy_menus} from "@/api/humanResources";

export default {
  name: 'RoleAuth',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 当前角色数据
    data: Object
  },
  data() {
    return {
      // 全选
      checkAll: false,
      // 全选状态
      isIndeterminate: false,
      // 权限数据
      authData: [],
      // 权限数据请求状态
      authLoading: false,
      // 提交状态
      loading: false,

      isCheck:false
    };
  },
  computed: {
    // 权限树选中数据
    checked() {
      let checked = [];
      this.$util.eachTreeData(this.authData, d => {
        if (d.checked && (!d.children || !d.children.length)) {
          checked.push(d.id);
        }
      });
      return checked;
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.query();
      }
    }
  },
  methods: {
    /* 查询权限数据 */
    query() {
      this.authData = [];
      if (!this.data) return;
      this.authLoading = true;
      // 获取到全部路由权限
      cascader_list().then(res => {
        console.log(res)
        if (res.code === 200) {
          this.authData = this.$util.toTreeData(res.data, 'id', 'pid');
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });

      setTimeout(() => {
        // 获取菜单关联接口
        get_relevancy(this.data.id).then(res => {
          console.log(res)
          if (res.code === 200) {
            // 这种写法为了解决默认回显父节点时子节点都选中问题
            this.isCheck= true  //重点：给数节点赋值之前 先设置为true
            this.$nextTick(() => {
              //因为我是根据数据id来判断选中所以使用setCheckedKeys，具体可以查看element官网api
              this.$refs.tree.setCheckedKeys(res.data) //给树节点赋值
              this.isCheck= false //重点： 赋值完成后 设置为false
            })

            // tree默认绑定的数据
            // this.$refs.tree.setCheckedKeys(res.data)
            // 如果第一条是空的选中状态则为false
            if(res.data[0] == ''){
              this.isIndeterminate = false;
              this.checkAll = false;
            }else {
              //与全选关联起来，判断是否全部选中还是部分选中
              let menuOptions = [];
              menuOptions = this.convertTreeData(res.data);
              let checkedCount = res.data.length;
              this.checkAll = checkedCount === menuOptions.length;
              this.isIndeterminate = checkedCount > 0 && checkedCount < this.authData.length;
            }
            // 关闭加载状态
            this.authLoading = false;
          } else {
            this.authLoading = false;
            this.$message.error(res.msg);
          }
        }).catch(e => {
          this.authLoading = false;
          this.$message.error(e.message);
        });
      },1000)
    },

    // tree 结构转化成一维数组
    convertTreeData(menuOptions) {
      for (let i = 0; i < menuOptions.length; i++) {
        if (menuOptions[i].children != undefined) {
          const temp = menuOptions[i].children;
          delete menuOptions[i].children;
          menuOptions = menuOptions.concat(temp);
        }
      }
      return menuOptions;
    },

    /* 保存权限分配 */
    save() {
      this.loading = true;
      let ids = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
      let data = {
        ids: ids
      }
      relevancy_menus(this.data.id,data).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.loading = false;
          this.updateVisible(false);
        } else {
          this.loading = false;
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },

    // 全选
    handleCheckAllChange() {
      if (this.checkAll) {
        //全选
        this.$refs.tree.setCheckedNodes(this.authData);
      }else{
        //取消选中
        this.$refs.tree.setCheckedKeys([]);
      }
      this.isIndeterminate = false;
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    }
  }
}
</script>

<style scoped>
</style>
