<template>
  <!--角色管理-->
  <div style="padding: 20px;">
    <div v-if="showUser === false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="90px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="名称/编码"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="reload">查询
                    </el-button>
                    <el-button @click="reset">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" @click="openEdit(null)"  v-auths="[`${$config.uniquePrefix}humanResources:role:save`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">新增角色</span>
                </el-button>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >
              <!--自定义列显示 示例-->
              <template v-slot:organization_category= "{ scope }">
                <div v-for="(item) in dict.type.organization_type">
                  <span v-if="scope.row.organization_category == parseInt(item.value)">{{item.label}}</span>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <!--<el-link :underline="false" type="warning" @click="openUser(scope.row)">用户</el-link>-->
                <el-link :underline="false" type="warning" @click="openAuth(scope.row)" v-auths="[`${$config.uniquePrefix}humanResources:role:status`]">分配权限</el-link>
                <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}humanResources:role:put`]">编辑</el-link>
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" :underline="false" type="info" v-auths="[`${$config.uniquePrefix}humanResources:role:delete`]">删除</el-link>
                </el-popconfirm>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--角色编辑-->
      <Edit :data="current" :dict="dict" :visible.sync="showEdit" @done="getList"/>
    </div>

    <!--用户模块-->
    <User v-if="showUser === true" :data="Usercurrent" :dict="dict" @showuser="show"/>

    <!-- 权限分配弹窗 -->
    <role-auth
        :data="authcurrent"
        :visible.sync="showAuth"/>

  </div>
</template>

<script>
//引入角色编辑
import Edit from './components/edit.vue'
//引入用户模块
import User from './components/user.vue'

//引入权限分配组件
import RoleAuth from './role-auth.vue'

// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  Delroles,
  getroles,
} from '@/api/humanResources'
import Vue from "vue";

export default {
  // 组件生效
  components: {
    Edit,
    User,
    RoleAuth
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        // {
        //   label: "角色编码",
        //   // type: "index",
        //   // width: "70"
        //   prop: "coding",
        //   isShow: true
        // },
        {
          label: "角色名称",
          prop: "name",
          isShow: true
        },
        // {
        //   label: "创建人",
        //   prop: "realname",
        //   isShow: true
        // },
        {
          label: "创建时间",
          prop: "create_time",
          isShow: true
        },
        // {
        //   label: "机构类别",
        //   prop: "organization_category",
        //   slot: 'organization_category',
        //   isShow: true
        // },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      //编辑
      current:null,
      showEdit:false,

      //字典
      dict: {
        type:{}
      },

      //用户模块
      Usercurrent:null,
      showUser:false,

      //权限
      authcurrent:null,
      showAuth:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '人力字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    this.getList();
  },

  methods:{
    /* 刷新表格 */
    reload() {
      this.getList();
    },
    /* 重置搜索 */
    reset() {
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      };
      this.reload();
    },

    // 获取列表
    getList(){
      getroles(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },

    // 删除
    del(row){
      Delroles(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    //编辑角色
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    //点击用户
    openUser(row){
      this.Usercurrent = row;
      this.showUser = true;
    },
    //接收用户子组件传递的数据
    show(value){
      this.showUser = value;
    },

    //点击分配权限
    openAuth(row){
      this.authcurrent = row;
      this.showAuth = true;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
